const items = {
  mounts: [
    { id: 13335, name: "Deathcharger's Reins", rate: 0.008 },
    { id: 30480, name: "Fiery Warhorse's Reins", rate: 0.01 },
    { id: 32458, name: "Ashes of Al'ar", rate: 0.01 },
    { id: 32768, name: "Reins of the Raven Lord", rate: 0.02 },
    { id: 35513, name: "Swift White Hawkstrider", rate: 0.04 },
    { id: 43952, name: "Reins of the Azure Drake", rate: 0.04 },
    { id: 43953, name: "Reins of the Blue Drake", rate: 0.04 },
    { id: 44083, name: "Reins of the Grand Black War Mammoth", rate: 0.01 },
    { id: 44151, name: "Reins of the Blue Proto-Drake", rate: 0.01 },
    { id: 45693, name: "Mimiron's Head", rate: 0.01 },
    { id: 49636, name: "Reins of the Onyxian Drake", rate: 0.015 },
    { id: 50818, name: "Invincible's Reins", rate: 0.01 },
    {
      id: 63040,
      name: "Reins of the Drake of the North Wind",
      rate: 0.007,
    },
    { id: 63041, name: "Reins of the Drake of the South Wind", rate: 0.01 },
    { id: 63043, name: "Reins of the Vitreous Stone Drake", rate: 0.007 },
    { id: 68823, name: "Armored Razzashi Raptor", rate: 0.008 },
    { id: 68824, name: "Swift Zulian Panther", rate: 0.01 },
    { id: 69224, name: "Smoldering Egg of Millagazor", rate: 0.01 },
    { id: 71665, name: "Flametalon of Alysrazor", rate: 0.02 },
    { id: 77067, name: "Reins of the Blazing Drake", rate: 0.01 },
    { id: 77069, name: "Life-Binder's Handmaiden", rate: 0.02 },
    { id: 78919, name: "Experiment 12-B", rate: 0.01 },
    {
      id: 87771,
      name: "Reins of the Heavenly Onyx Cloud Serpent",
      rate: 0.003,
    },
    { id: 87777, name: "Reins of the Astral Cloud Serpent", rate: 0.01 },
    { id: 89783, name: "Son of Galleon's Saddle", rate: 0.003 },
    { id: 93666, name: "Spawn of Horridon", rate: 0.03 },
    {
      id: 94228,
      name: "Reins of the Cobalt Primordial Direhorn",
      rate: 0.003,
    },
    {
      id: 95057,
      name: "Reins of the Thundering Cobalt Cloud Serpent",
      rate: 0.003,
    },
    { id: 95059, name: "Clutch of Ji-Kun", rate: 0.03 },
  ],
  pets: [
    { id: 93033, name: "Harbinger of Flame", rate: 0.16 },
    { id: 93034, name: "Corefire Imp", rate: 0.18 },
    { id: 93035, name: "Ashstone Core", rate: 0.17 },
    { id: 93036, name: "Untamed Hatchling", rate: 0.24 },
    { id: 93037, name: "Death Talon Whelpguard", rate: 0.23 },
    { id: 93038, name: "Chrominius", rate: 0.24 },
    { id: 93039, name: "Viscidus Globule", rate: 0.3 },
    { id: 93040, name: "Anubisath Idol", rate: 0.18 },
    { id: 93041, name: "Mini Mindslayer", rate: 0.18 },
    { id: 93029, name: "Stitched Pup", rate: 0.31 },
    { id: 93030, name: "Giant Bone Spider", rate: 0.26 },
    { id: 93032, name: "Fungal Abomination", rate: 0.26 },
  ],
  toys: [
    { id: 108631, name: "Crashin' Thrashin' Roller Controller", rate: 0.1 },
    { id: 108633, name: "Crashin' Thrashin' Cannon Controller", rate: 0.1 },
    { id: 108634, name: "Crashin' Thrashin' Mortar Controller", rate: 0.1 },
    { id: 86578, name: "Eternal Warriors Sigil", rate: 0.14 },
    { id: 86584, name: "Hardened Shell", rate: 0.18 },
  ],
};

export default items;
